<template>
  <div>
    <van-cell-group title="选择来料">
      <template #title>
        <el-row type="flex" justify="space-between">
          <el-col :span="12">
            <van-cell :value="materiel.materielCode"></van-cell>
          </el-col>
          <el-col :span="8">
            <van-button block type="info" icon="todo-list" @click="handleSelectMateriel" round>选择来料</van-button>
          </el-col>
        </el-row>
      </template>
      <van-cell title="来料时间" :value="materiel.materielTime"></van-cell>
      <van-cell title="址址" :value="materiel.address"></van-cell>
      <van-cell title="质量" :value="materiel.quality"></van-cell>
    </van-cell-group>
    <van-cell-group :border="false">
      <template #title>
        <el-row type="flex" justify="space-between">
          <el-col :span="12">
          </el-col>
          <el-col :span="8">
            <van-button block type="info" icon="add" @click="createJSY001" round>生成试件</van-button>
          </el-col>
        </el-row>
      </template>

    </van-cell-group>
    <van-tabs v-model="activeSpecimen" v-if="specimenList.length>0" :swipe-threshold="3">
      <van-tab v-for="(item,index) in specimenList" :key="index" :title="item.specimenCode">
        <van-field label="击实次数" type="digit" v-model.number="item.jiShiCiShu"></van-field>
        <van-field label="击实重量" type="number" v-model.number="item.jiShiZhongLiang"></van-field>
        <van-field label="试件直径" type="number" v-model.number="item.shiJianZhiJing"></van-field>
        <el-row type="flex" justify="space-around">
          <el-button type="primary" icon="el-icon-check" @click="handleSaveJSY001(index)" round>保存试件</el-button>
          <el-button type="danger" icon="el-icon-delete" @click="handleDeleteJSY001(index)" round>删除试件</el-button>
        </el-row>
      </van-tab>
    </van-tabs>
    <van-empty v-else description="无试件" />

    <WapSelectMateriel ref="wapSelectMateriel" @select="handleAfterSelectMateriel"></WapSelectMateriel>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import WapSelectMateriel from "../../../components/WapSelectMateriel";
Vue.use(Vant);
export default {
  components: { WapSelectMateriel },
  props:['serialNumber'],
  data() {
    return {
      materiel: {},
      specimenList: [], //Res_JSY001视图的列表
      activeSpecimen: null,
    };
  },
  methods: {
    handleSelectMateriel() {
      let that = this;
      that.$refs.wapSelectMateriel.selectMateriel();
    },
    handleAfterSelectMateriel(v) {
      let that = this;
      that.materiel = v;
      that.axios
        .post("JSY001/GetListByMaterielId", { onlyInt: that.materiel.id })
        .then(function (response) {
          that.specimenList = response.data.data;
        });
    },
    createJSY001() {
      let that = this;
      if (that.materiel.id) {
        that.axios
          .post("JSY001/CreateJSY001", {
            materielId: that.materiel.id,
            serialNumber: that.serialNumber,
          })
          .then(function (response) {
            that.specimenList.unshift(response.data.data);
          });
      } else {
        that.$toast.fail("请选择来料");
      }
    },
    handleSaveJSY001(index) {
      let that = this;
      that.axios
        .post("JSY001/SaveJSY001", that.specimenList[index])
        .then(function (response) {
          if (response.data.code == 101) {
            that.$notify({ type: "success", message: "保存成功" });
          }
        });
    },
    handleDeleteJSY001(index) {
      let that = this;
      that.$dialog.confirm({ message: "确定要删除吗？" }).then(function () {
        that.axios
          .post("JSY001/DeleteJSY001", {
            onlyInt: that.specimenList[index].id,
          })
          .then(function (response) {
            if (response.data.code == 101) {
              that.specimenList.splice(index, 1);
            }
          });
      });
    },
  },
};
</script>

<style>
.test {
  position: sticky;
}
</style>