<template>
  <van-popup v-model="popupVisible" position="bottom" style="height:70%" round>
    <van-list v-model="listLoading" :finished="listFinished" @load="getMyMateriel">
      <van-cell-group title="选择来料">
        <van-cell v-for="(item,index) in materielList" :key="index" :title="item.remark" :value="item.materielCode" @click="handleClick(item)">
        </van-cell>
      </van-cell-group>
    </van-list>
  </van-popup>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
Vue.use(Vant);
export default {
  data() {
    return {
      popupVisible: false,
      materielList: [],
      listLoading: false,
      listFinished: false,
      currentPage: 1,
    };
  },
  methods: {
    selectMateriel() {
      let that = this;
      that.popupVisible = true;
    },
    getMyMateriel() {
      let that = this;
      that.axios
        .post("Materiel/GetMyMateriel", {
          currentPage: that.currentPage,
        })
        .then(function (response) {
          response.data.data.data.forEach((element) => {
            that.materielList.push(element);
          });
          that.listLoading = false;
          if (that.materielList.length >= response.data.data.total) {
            that.listFinished = true;
          } else {
            that.currentPage++;
          }
        });
    },
    handleClick(item) {
      let that = this;
      that.$emit("select", item);
      that.popupVisible = false;
    },
  },
};
</script>

<style>
</style>